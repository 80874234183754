import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
	UncontrolledTooltip,  
	Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
	Button
} from 'reactstrap';
import Spinner from '../../Spinner';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import { Icon } from '@assets';
import { useQuery, useMutation } from '@apollo/client';
import { INVOICE, INVOICES, UPDATE_INVOICE_STATUS, SEND_INVOICE, DOWNLOAD_INVOICE, INVOICE_HISTORY_MULTI_DELETE } from '@api';

export default function InvoiceShow({
	setSidebarState
}){
	const { id: invoiceId } = useParams();
	const [invoiceData, setInvoiceData] = useState(null);
	const history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
	const [isCheckedId, setIsCheckedId] = useState([]);
  const [isProgress, setIsProgress] = useState(false);

	const {data: invoice, loading: invoiceLoading, refetch: invoiceRefetch } = useQuery(INVOICE, {
		variables: {id: parseInt(invoiceId)},
		fetchPolicy: "network-only",
		onCompleted: (data) => {
			setInvoiceData(data.invoice);
		}
	});

	useEffect(() => {
		if (invoice){
			setInvoiceData(invoice.invoice)
		}
	}, [invoice])

	const [updateInvoiceStatus] = useMutation(UPDATE_INVOICE_STATUS, {
		onCompleted: (data) => {
			if (data.updateInvoiceStatus.errors.length > 0) {
				toast(data.updateInvoiceStatus.message, { type: 'error' });
			} else {
				toast(data.updateInvoiceStatus.message, { type: 'success' });
			}
			invoiceRefetch();
		},
		refetchQueries: [INVOICE, INVOICES],
    onError: (e) => {
      console.log(e);
    }
	});

	const [downloadInvoice] = useMutation(DOWNLOAD_INVOICE, {
		onCompleted: async (data) => {
			if ( data.downloadInvoice.errors === null || data.downloadInvoice.errors.length === 0) {
				try {
          if (data.downloadInvoice.invoiceDownloadLink) {
            const invoicePdfLink = data.downloadInvoice.invoiceDownloadLink;
						// Open the PDF in a new tab
            window.open(invoicePdfLink, '_blank');
            const fileName = invoicePdfLink.substring(invoicePdfLink.lastIndexOf('/') + 1) || 'Invoice.pdf';
            // Fetch the PDF file
            // const fileResponse = await fetch(invoicePdfLink);
						const fileResponse = await fetch(invoicePdfLink, {
							method: 'GET',
							credentials: 'include',
						});
            const blob = await fileResponse.blob();
            // Create a blob URL
            const blobUrl = window.URL.createObjectURL(blob);
            // Create a temporary anchor element to trigger the download
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            // Clean up the DOM
            link.remove();
            window.URL.revokeObjectURL(blobUrl);
          }
        } catch (err) {
          console.error('Error downloading invoice:', err);
        }
				toast(() => (
					<a href={data.downloadInvoice.invoiceDownloadLink} target='_blank' download>
						Click here to download PDF
					</a>
				), { type: 'success' });
			} else {
				toast(data.downloadInvoice.message, { type: 'error' });
			}
		},
    onError: (e) => {
      console.log(e);
    }
	});

	const [sendInvoiceMutation] = useMutation(SEND_INVOICE, {
		onCompleted: (data) => {
			if ( data.sendInvoice.errors === null || data.sendInvoice.errors.length === 0) {
				toast(data.sendInvoice.message, { type: 'success' });
			} else {
				toast(data.sendInvoice.message, { type: 'error' });
			}
			invoiceRefetch();
		},
		refetchQueries: [INVOICE, INVOICES],
    onError: (e) => {
      console.log(e);
    }
	});

	const [invoiceMultiDelete] = useMutation(INVOICE_HISTORY_MULTI_DELETE, {
    onCompleted: (data) => {
      setDeleteModal(false);
      toast(data.invoicesMultiDelete.message, { type: 'success' });
      setIsProgress(false);
			setSidebarState(false);
			invoiceRefetch();
    },
    refetchQueries: [INVOICE, INVOICES],
    onError: (e) => {
      console.log(e);
    },
  });

	function toolTip(icon, txt, id){
		return (
			<>&nbsp;&nbsp;&nbsp;&nbsp;
				<span
					href="#"
					id={'tooltip-'+id}
					style={{
						color: 'black',
						textDecoration: 'underline'
					}}
				>
					{icon}
				</span>
				<UncontrolledTooltip
					placement="top"
					target={'tooltip-'+id}
					style={{
						color: 'white',
						backgroundColor: 'black',
						fontWeight: 'bold'
					}}
				>
					{txt}
				</UncontrolledTooltip>
			</>
		)
	}

	function sendInvoice(id) {
		sendInvoiceMutation({
			variables: {id: Number(id)}
		})
	}

	function invoiceDownload(id) {
		downloadInvoice({
			variables: {id: Number(id)}
		})
	}

	function invoiceStatusUpdate(id, status){
		updateInvoiceStatus({
			variables: {id: Number(id), newStatus: status}
		})
	}

	const handleSingleDelete = (id) => {
    setIsCheckedId([parseInt(id)]);
    setDeleteModal(true);
  };

	const handleDelete = async () => {
    setIsProgress(true);
    await invoiceMultiDelete({
      variables: {
        ids: isCheckedId,
      },
    });
  };

	return (
		<>
		{
			invoiceLoading && <Spinner />
		}
		{
			invoiceData ? 
			<div className='invoice-show-popup'>
				<Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)}>
					<ModalHeader toggle={() => setDeleteModal(!deleteModal)}>Delete Confirmation</ModalHeader>
					<ModalBody>
						Are you sure you want to delete {isCheckedId.length} invoice
						{isCheckedId.length > 1 ? 's' : ''}?
					</ModalBody>
					<ModalFooter>
						<Button
							color='danger'
							disabled={isProgress}
							onClick={handleDelete}
						>
							Yes
						</Button>
						<Button color='success' onClick={() => setDeleteModal(false)}>
							No
						</Button>
					</ModalFooter>
				</Modal>

				<Row className="mt-4 px-5">
					<Col md='12'>
						<Row>
							<Col md='8'>
								<h1>{invoiceData.user.brokerageName && invoiceData.user.brokerageName.length > 1 ? invoiceData.user.brokerageName : invoiceData.user.name }</h1>
							</Col>
							<Col md='4' className='invoice-show-icon-menu'>
								{invoiceData.status !== 'paid' && 
									toolTip(
										<Icon 
											icon='edit' 
											className='invoice-show-icon' 
											size='lg' 
											onClick={() => {
												setSidebarState(true);
												history.push(`/invoices/edit/${invoiceData.id}`);
											}}
										/>, "Edit", 1)
								}
								{toolTip(<Icon icon='paper-plane' className='invoice-show-icon' size='lg' onClick={() => sendInvoice(invoiceData.id)}/>, invoiceData.status !== 'drafted' ? "Resend Invoice" : "Send Invoice", 2)}
								{toolTip(<Icon icon='download' className='invoice-show-icon' size='lg'  onClick={() => invoiceDownload(invoiceData.id)}/>, "Download", 3)}
								{
									invoiceData.status === 'drafted' ? 
									<>
										{toolTip(<Icon icon='file-export' className='invoice-show-icon' size='lg' onClick={() => invoiceStatusUpdate(invoiceData.id, "sent")}/>, "Set As Sent", 4)}
										{toolTip(<Icon icon='file-invoice-dollar' className='invoice-show-icon' size='lg' onClick={() => invoiceStatusUpdate(invoiceData.id, "paid")}/>, "Set As Paid", 5)}
									</> : invoiceData.status === 'sent' ?
									<>
										{toolTip(<Icon icon='file-invoice-dollar' className='invoice-show-icon' size='lg' onClick={() => invoiceStatusUpdate(invoiceData.id, "paid")}/>, "Set As Paid", 5)}
									</> : 
									<>
										{toolTip(<Icon icon='undo' className='invoice-show-icon' size='lg' onClick={() => invoiceStatusUpdate(invoiceData.id, "sent")}/>, "Set As Unpaid", 5)}
									</>
								}
								{toolTip(<Icon icon='trash' className='invoice-show-icon' size='lg' onClick={() => handleSingleDelete(invoiceData.id)}/>, "Delete", 6)}
							</Col>	
						</Row>
					</Col>
				</Row>
				<Row className='px-5 mt-3'>
					<Col md='6'>
						<p>Invoice ID: {invoiceData.invoiceNumber}</p>
					</Col>
					<Col md='6' align='right'>
						<p>{invoiceData.submittedOn ? `Date: ${new Date(invoiceData.submittedOn).toLocaleDateString('en-US')}` : `Date: ${new Date(invoiceData.createdAt).toLocaleDateString('en-US')}`}</p>
					</Col>
				</Row>
				<div className='invoice-divider'>
					{/* <br/> */}
				</div>
				<Row className='px-5'>
					<Col md='12'>
						<Row className='mt-4'>
							<Col md='3'>
								<strong>Bill To:</strong>
							</Col>
							<Col md='9'>
								{invoiceData.property.name}
							</Col>
						</Row>
						<Row>
							<Col md='3'>
								<strong>Client:</strong>
							</Col>
							<Col md='9'>
								{invoiceData.client.name}
							</Col>
						</Row>
						<Row >
							<Col md='3'>
								<strong>Unit No:</strong>
							</Col>
							<Col md='9'>
								{invoiceData.unitNumber}
							</Col>
						</Row>
						<Row>
							<Col md='3'>
								<strong>Move-In-Date:</strong>
							</Col>
							<Col md='9'>
								{new Date(invoiceData.moveIn).toLocaleDateString('en-US')}
							</Col>
						</Row>
						<Row>
							<Col md='3'>
								<strong>Lease Term:</strong>
							</Col>
							<Col md='9'>
								{invoiceData.leaseTerm} Months
							</Col>
						</Row>
						<Row className='mt-3'>
							<Col md='3'>
								<strong>Rent:</strong>
							</Col>
							<Col md='9'>
								${invoiceData.leaseAmount}/month
							</Col>
						</Row>
						<Row>
							<Col md='3'>
								<strong>Commission:</strong>
							</Col>
							<Col md='9'>
								{invoiceData.isPercentCommission ? `${invoiceData.commission}%` : `$${invoiceData.commission}`}
							</Col>
						</Row>
						<Row className='mb-4'>
							<Col md='3'>
								<strong>Concession:</strong>
							</Col>
							<Col md='9'>
								{invoiceData.concession ? invoiceData.concession : "-"}
							</Col>
						</Row>
						<hr/>
						<Row>
							<Col md='6'>
							</Col>
							<Col md='6'>
								<Row>
									<Col  md='6'>
										<strong>TOTAL</strong>
									</Col>
									<Col  md='6' align='right'>
										<strong>${invoiceData.totalInvoiceAmount}</strong>
									</Col>
								</Row>
								<hr/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='px-5 mt-5'>
					<Col md='12'>
						<Row>
							<Col md='6'>
								<strong>PAYABLE TO:</strong><br/>
								{invoiceData.user.brokerageName && invoiceData.user.brokerageName.length > 1 ? invoiceData.user.brokerageName : invoiceData.user.name }<br/>
								{invoiceData.user.street1}<br/>
								{invoiceData.user.street2}<br/>
								{invoiceData.user.cityName}<br/>
								{invoiceData.user.stateName}{' '}
								{invoiceData.user.zip}
							</Col>
							<Col md='6'>
							<strong>AGENT:</strong><br/>
							{invoiceData.user.name}<br/>
							{invoiceData.user.phoneNo}<br/>
							{invoiceData.user.email}<br/>
							</Col>
						</Row>
					</Col>
				</Row>
				<br/>
				<br/>
			</div> : <div className='m-5'><h2>Loading Invoice...</h2></div>
		}
		</>
	)
}