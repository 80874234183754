import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Card, CardHeader, CardBody, Collapse, Button, CardTitle } from 'reactstrap';
import { unitTypes, lastUpdatedOn } from '@sparklib';
import TabList from './TabList';
import _ from 'lodash';
import Slider from '../../../components/Slider';
import SmallMap from '../../Recommendation/SmallMap';
import { Icon } from '@assets';
import { PROPERTY, USER_PROPERTY_NOTE, UPDATE_USER_PROPERTY_NOTES, CREATE_USER_PROPERTY_NOTES } from '@api';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import ScrollToTopButton from './ScrollToTopButton';
import { toast } from 'react-toastify';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default function ShowProperty(props) {
  const [property, setProperty] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [details, setDetails] = useState(false);
  const newToggle = () => setDetails(!details);
  const [brokerNotesCollapseOpen, setBrokerNotesCollapseOpen] = useState(false);
  const toggleBrokerNotes = () => setBrokerNotesCollapseOpen(!brokerNotesCollapseOpen)
  const [agentNotesCollapseOpen, setAgentNotesCollapseOpen] = useState(false);
  const toggleAgentNotes = () => setAgentNotesCollapseOpen(!agentNotesCollapseOpen)
  const [selfNotes, setSelfNotes] = useState('');
  const [agentNotes, setAgentNotes] = useState('');
  const [selfNoteId, setSelfNoteId] = useState();
  const [agentNoteId, setAgentNoteId] = useState();

  useEffect(() => {
    if (props.property) {
      setProperty(props.property);
    }
  }, [props.property]);

  const { id: propertyId } = useParams();
  const { loading, data: propertyData } = useQuery(PROPERTY, {
    variables: {
      id: String(propertyId),
      ...props.queryVariables,
    },
    skip: props.property || !propertyId,
    onCompleted: (data) => {
      if (data) {
        setProperty(data.property);
      }
    },
  });

  const { data: userPropertyNoteData, refetch: refetchUserPropertyNote } = useQuery(USER_PROPERTY_NOTE, {
    variables: {
      propertyId: String(property?.id),
      noteType: "for_agents"
    },
    skip: !property,
    onCompleted: (data) => {
      if (data) {
        setAgentNotes(data.userPropertyNote?.notes);
        setAgentNoteId(Number(data.userPropertyNote?.id));
      }
    },
  });

  const { data: userPropertySelfNoteData, refetch: refetchUserPropertySelfNote } = useQuery(USER_PROPERTY_NOTE, {
    variables: {
      propertyId: String(property?.id),
      noteType: "for_self"
    },
    skip: !property,
    onCompleted: (data) => {
      if (data) {
        setSelfNotes(data.userPropertyNote?.notes);
        setSelfNoteId(Number(data.userPropertyNote?.id));
      }
    },
  });

  const [updateUserPropertyNote] = useMutation(UPDATE_USER_PROPERTY_NOTES, {
    onCompleted: (data) => {
      if (data.updateUserPropertyNote.message) {
        refetchUserPropertySelfNote();
        toast(data.updateUserPropertyNote.message, { type: 'success' });
      }
      if (data.updateUserPropertyNote.errors && data.updateUserPropertyNote.errors.length > 0) {
        data.updateUserPropertyNote.errors.forEach(err => toast(err, { type: 'error' }));
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const [createUserPropertyNote] = useMutation(CREATE_USER_PROPERTY_NOTES, {
    onCompleted: (data) => {
      if (data.createUserPropertyNote.message) {
        refetchUserPropertyNote();
        refetchUserPropertySelfNote();
        toast(data.createUserPropertyNote.message, { type: 'success' });
      }
      if (data.createUserPropertyNote.errors && data.createUserPropertyNote.errors.length > 0) {
        data.createUserPropertyNote.errors.forEach(err => toast(err, { type: 'error' }));
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const handleUpdateUserPropertyNote = (noteType) => {
    const noteId = noteType === 'for_self' ? selfNoteId : agentNoteId
    if (noteId) {
      updateUserPropertyNote({
        variables: {
          id: noteType === 'for_self' ? selfNoteId : agentNoteId,
          notes: noteType === 'for_self' ? selfNotes : agentNotes,
        },
      });
    } else {
      createUserPropertyNote({
        variables: {
          propertyId: String(property.id),
          notes: noteType === 'for_self' ? selfNotes : agentNotes,
          noteType: noteType,
        },
      });
    }
  };

  function groupFloorPlansArray(floorPlans, key) {
    // floorPlans = floorPlans.filter((fp) => fp.units.length > 0);
    // floorPlans = floorPlans.filter(
    //   (floorPlansData) => isAvailableCount(floorPlansData.units).length > 0
    // );
    return floorPlans.reduce(function (groupedFloorPlans, floorPlan) {
      (groupedFloorPlans[floorPlan['bed']] =
        groupedFloorPlans[floorPlan['bed']] || []).push(floorPlan);
      return groupedFloorPlans;
    }, {});
  }

  let groupedFloorPlans = property
    ? groupFloorPlansArray(property.floorPlans, 'bed')
    : [];

  let defaultTab = Object.keys(unitTypes).find((type) =>
    Object.keys(groupedFloorPlans).find((groupedType) => type === groupedType)
      ? true
      : false
  );

  const disabledTab = Object.keys(unitTypes).map((type => !groupedFloorPlans[type]));
  const allTabsDisabled = disabledTab.every(isDisabled => isDisabled);

  const [activeTab, setActiveTab] = useState(unitTypes[defaultTab] || '');
  useEffect(() => {
    groupedFloorPlans = property
      ? groupFloorPlansArray(property.floorPlans, 'bed')
      : [];
    defaultTab = Object.keys(unitTypes).find((type) =>
      Object.keys(groupedFloorPlans).find((groupedType) => type === groupedType)
        ? true
        : false
    );
    setActiveTab(unitTypes[defaultTab]);
  }, [property]);

  const propertyImages = property?.imageUrls?.map((image, index) => ({
    src: image,
    altText: property.name,
  }));

  const renderPolicyButton = (policyItem) => {
    if (property.policyItems.includes(policyItem)) {
      return (
        <button className="btn btn-outline-danger btn-sm mx-2 my-2" disabled>{policyItem}</button>
      );
    }
  };

  return property ? (
    <>
      <div className='p-4 edit-popup'>
        <Row>
          <Col md='12' className='d-md-flex align-items-center mb-3'>
            <Slider items={propertyImages} className='slider rounded-medium' isLazyLoad={false} propertyId={property.id}/>
          </Col>
        </Row>
        <Row>
          <Col md='12' className='d-md-flex align-items-center mb-3'>
            <h3 className='d-inline-block mb-md-0 mr-4' value={property.name}>
              {property.name} <small>({property.neighborhood?.name})</small>
            </h3>
            {["45+", "55+", "60+", "62+", "Accepts Section 8", "Tax Credit Property", "Income Restrictions"].map((item) => (
              renderPolicyButton(item)
            ))}
              {/*<h5 className='d-inline-block mb-md-0'>
              <Icon icon='star' color='var(--theme-yellow)' />{' '}
              {property.googleRating}
            </h5>*/}
          </Col>

          {property.specials?.trim().length > 1 ? (
            <Col lg='12'>
              <p
                className='p-2'
                style={{
                  background: 'var(--silver)',
                  borderRadius: 'var(--rounded-small)',
                }}
              >
                <Icon icon='tag' /> Specials: {property.specials}
              </p>
            </Col>
          ) : null}

          <Col md='12'>
            <div className="d-block d-sm-none">
            <Button color="light" className='my-3 mt-4 btn-rounded btn btn-md float-right' onClick={newToggle}>
							{details ? "Collapse" : "Expand Full Details"}&nbsp;{ details ? <Icon icon='chevron-up' /> : <Icon icon='chevron-down' />}
						</Button>
            <Collapse isOpen={details}>
              <Card className = "w-100">
                <CardBody>
                  <p>
                    <Icon icon='map-marker-alt' />{' '}
                    {property.address ? (
                    <a href={'add:' + property.address} target='_blank'>
                      {property.address}
                    </a>
                    ) : (
                     '-'
                     )}
                  </p>
                
                  <p>
                    <Icon icon='envelope' />{' '}
                    <a href={'mailto:' + property.email} target='_blank'>
                      {property.email}
                    </a>
                  </p>

                  {property.secondaryEmail && 
							      <p>
								      <Icon icon='envelope' />{' '}
								      <a href={'mailto:' + property.secondaryEmail} target='_blank'>
									     {property.secondaryEmail}
							  	    </a>
						  	    </p>
						      }

                  {property.otherEmail && 
						  	    <p>
								      <Icon icon='envelope' />{' '}
								      <a href={'mailto:' + property.otherEmail} target='_blank'>
									     {property.otherEmail}
								      </a>
							      </p>
						      }

                  <p>
                    <Icon icon='phone-alt' />{' '}
                    {property.phone ? (
                    <a href={'tel:' + property.phone} target='_blank'>
                     {property.phone}
                    </a>
                    ) : (
                     '-'
                    )}
                  </p>

                  <p>
                    <Icon icon='calendar-alt' /> Built Year:{' '}
                    {property.builtYear ? property.builtYear : '-'}
                  </p>

                  <p>
                    <Icon icon='calendar-alt' /> Renovated:{' '}
                    {property.renovated ? property.renovated : '-'}
                  </p>

                  <div className='pb-3'>
                    {property.webLink ? (
                      <p className='mr-3 d-inline'>
                        <Icon icon='globe' />
                        <a href={property.webLink} target='_blank'>
                          Website
                        </a>
                      </p>
                    ) : null}<br></br>
                  </div>

                  <div className='pb-3'>
                    {property.googleMap ? (
                      <p className='mr-3 d-inline'>
                        <Icon icon='map-marked-alt' />
                        <a href={property.googleMap} target='_blank'>
                          Map
                      ` </a>
                      </p>
                    ) : null}
                  </div>
                             
                  <p>
                    <Icon icon='building' /> Management Co.:{' '}
                    {property.managementCompany ? property.managementCompany : '-'}
                  </p>

                  <p>
                    <Icon icon='shield-alt' /> Escort %:{' '}
                    {property.escort ? property.escort : '-'}
                  </p>
         
                  <p>
                    <Icon icon='shield-alt' /> Send %:{' '}
                    {property.sendEscort ? property.sendEscort : '-'}
                  </p>
      
                  <p>
                    <Icon icon='shield-alt' /> Bonus:{' '}
                    {property.bonus ? property.bonus : '-'}
                  </p>
         
                  {property.googleReviewLink ? (
                    <p>
                      <Icon icon='star' />
                      <a href={property.googleReviewLink} target='_blank'>
                        Review
                      </a>
                    </p>
                  ) : null}
          
                  {property.photoGalleryLink ? (
                      <p>
                       <Icon icon='images' />
                        <a href={property.photoGalleryLink} target='_blank'>
                          Gallery
                        </a>
                      </p>
                  ) : null}
          
                  {property.floorPlanLink ? (
                      <p>
                        <Icon icon='home' />
                        <a href={property.floorPlanLink} target='_blank'>
                          Floor Plan
                        </a>
                      </p>
                  ) : null}
         
                  {property.virtualTourLink ? (
                      <p>
                        <Icon icon='video' />
                        <a href={property.virtualTourLink} target='_blank'>
                          Virtual Tour
                        </a>
                      </p>
                  ) : null}
          
                  {property.feesSheetUrl ? (
                      <p>
                        <Icon icon='dollar-sign' />
                        <a href={property.feesSheetUrl} target='_blank'>
                          Fee Sheet
                        </a>
                      </p>
                  ) : null}
          
                  {property.siteMapUrl ? (
                    <p>
                      <Icon icon='map' />
                      <a href={property.siteMapUrl} target='_blank'>
                        Site Map
                      </a>
                    </p>
                  ) : null}
                </CardBody>
              </Card>
            </Collapse>
            </div>
          </Col>

          <Col md='12'>
            <div className="d-block d-sm-none">
              <Button color="light" className='my-3 mt-4 btn-rounded btn btn-md float-right' onClick={toggle}>
								{isOpen ? "Collapse Notes" : "Expand Notes"}&nbsp;{ isOpen ? <Icon icon='chevron-up' /> : <Icon icon='chevron-down' />}
							</Button>
              <Collapse isOpen={isOpen}>
                <div className='pb-2 mb-2'>
                  {property.schedulingNote?.length > 5 && (
                    <Card className = "w-100">
                      <CardBody>
                        <CardTitle tag="h5">
                          Scheduling Note:
                        </CardTitle>
                        {property.schedulingNote} 
                      </CardBody>
                    </Card>
                  )}
                </div>

                <div className='mb-2'>
                  {property.commissionNote?.length > 5 && (
                    <Card className = "w-100">
                      <CardBody>
                        <CardTitle tag="h5">
                          Commission Note:
                        </CardTitle>
                        {property.commissionNote} 
                      </CardBody>
                    </Card>
                  )}
                </div>

                <div className='mb-2'>
                  {property.addonItems.length > 0 && (
                  <Card className = "w-100">
                    <CardBody>
                      <CardTitle tag="h5">
                        Community Features / Amenities:
                      </CardTitle>
                      {property.addonItems.join(', ')} 
                    </CardBody>
                  </Card>
                  )}
                </div>

                <div className='mb-2'>
                  {property.policyItems.length > 0 && (
                  <Card className = "w-100">
                    <CardBody>
                      <CardTitle tag="h5">
                        Community Policies:
                      </CardTitle>
                     {property.policyItems.join(', ')} 
                    </CardBody>
                  </Card>
                  )}
                </div>

                <div className='mb-2'>
                  {property.virtualTours?.length > 5 && (
                  <Card className = "w-100">
                    <CardBody>
                      <CardTitle tag="h5">
                        Virtual Tour:
                      </CardTitle>
                    {property.virtualTours}
                    </CardBody>
                  </Card>
                 )}
                </div>

                <div className='mb-2'>
                  {property.parkingNote?.length > 5 && (
                    <Card className = "w-100">
                      <CardBody>
                        <CardTitle tag="h5">
                          Parking Note:
                        </CardTitle>
                        {property.parkingNote} 
                      </CardBody>
                    </Card>
                  )}
                </div>

              <div className='mb-2'>
              {property.invoicingNote?.length > 5 && (
                <Card className = "w-100">
                  <CardBody>
                  <CardTitle tag="h5">
                    Invoicing Note:
                  </CardTitle>
                    {property.invoicingNote}
                  </CardBody>
                </Card>
              )}
              </div>

              <div className='mb-2'>
              {property.additionalNote && (
                <Card className = "w-100">
                  <CardBody>
                  <CardTitle tag="h5">
                    Additional Note:
                  </CardTitle>
                    {property.additionalNote}
                  </CardBody>
                </Card>
              )}
              </div>

              <div className='mb-2'>
              {property.policyNote && (
                <Card className = "w-100">
                  <CardBody>
                  <CardTitle tag="h5">
                    Policy Note:
                  </CardTitle>
                    {property.policyNote}
                  </CardBody>
                </Card>
              )}
              </div>

              <div className='mb-2'>
                {property.specialsUpdatedAt && (
                  <Card className = "w-100">
                    <CardBody>
                      <CardTitle tag="h5">
                      Commissions Last Updated:
                      </CardTitle>
                      {property.specialsUpdatedAt ? moment(property.specialsUpdatedAt).fromNow() : '-'}
                    </CardBody>
                  </Card>
                )}
              </div>

              <div className='mb-2'>
                {property.unitsUpdatedAt && (
                  <Card className = "w-100">
                    <CardBody>
                      <CardTitle tag="h5">
                        Pricing Last Updated:
                      </CardTitle>
                      {allTabsDisabled ? 'Pricing and availability for this community is not currently available.' : (property.unitsUpdatedAt ? moment(property.unitsUpdatedAt).fromNow() : '-')}
                    </CardBody>
                  </Card>
                )}
              </div>
            </Collapse>
            </div>
            </Col>
          

          <Col lg='4'>
            <div className="d-none d-sm-block pb-3">
              {property.webLink ? (
                <p className='mr-3 d-inline'>
                  <Icon icon='globe' />
                  <a href={property.webLink} target='_blank'>
                    Website
                  </a>
                </p>
              ) : null}
              

              {property.googleMap ? (
                <p className='mr-3 d-inline'>
                  <Icon icon='map-marked-alt' />
                  <a href={property.googleMap} target='_blank'>
                    Map
                  </a>
                </p>
                ) : null}
            </div>
          </Col>  

          <Col lg='4'>
            <div className="d-none d-sm-block">
              <p>
                <Icon icon='map-marker-alt' />{' '}
                {property.address ? (
                  <a href={'add:' + property.address} target='_blank'>
                    {property.address}
                  </a>
                  ) : (
                  '-'
                )}
              </p>
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              <p>
                <Icon icon='phone-alt' />{' '}
                {property.phone ? (
                  <a href={'tel:' + property.phone} target='_blank'>
                    {property.phone}
                  </a>
                  ) : (
                  '-'
                )}
              </p>
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              <p>
                <Icon icon='envelope' />{' '}
                <a href={'mailto:' + property.email} target='_blank'>
                  {property.email}
                </a>
              </p>
              {property.secondaryEmail && 
							  <p>
								  <Icon icon='envelope' />{' '}
								  <a href={'mailto:' + property.secondaryEmail} target='_blank'>
									  {property.secondaryEmail}
								  </a>
							  </p>
					  	}
              {property.otherEmail && 
							  <p>
								  <Icon icon='envelope' />{' '}
								  <a href={'mailto:' + property.otherEmail} target='_blank'>
								  	{property.otherEmail}
								  </a>
							  </p>
					  	}
            </div>
          </Col>  

          <Col lg='4'>
            <div className="d-none d-sm-block">
              <p>
                <Icon icon='building' /> Management Co.:{' '}
                {property.managementCompany ? property.managementCompany : '-'}
              </p>
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              <p>
                <Icon icon='calendar-alt' /> Built Year:{' '}
                {property.builtYear ? property.builtYear : '-'}
              </p>
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              <p>
                <Icon icon='calendar-alt' /> Renovated:{' '}
                {property.renovated ? property.renovated : '-'}
              </p>
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              <p>
                <Icon icon='shield-alt' /> Escort %:{' '}
                {property.escort ? property.escort : '-'}
              </p>
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              <p>
                <Icon icon='shield-alt' /> Send %:{' '}
                {property.sendEscort ? property.sendEscort : '-'}
              </p>
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              <p>
                <Icon icon='shield-alt' /> Bonus:{' '}
                {property.bonus ? property.bonus : '-'}
              </p>
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              {property.googleReviewLink ? (
                <p className='mr-3 d-inline'>
                  <Icon icon='star' />
                  <a href={property.googleReviewLink} target='_blank'>
                    Review
                  </a>
                </p>
              ) : null}
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              {property.photoGalleryLink ? (
                <p className='mr-3 d-inline'>
                  <Icon icon='images' />
                  <a href={property.photoGalleryLink} target='_blank'>
                    Gallery
                  </a>
                </p>
              ) : null}
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              {property.floorPlanLink ? (
                <p className='mr-3 d-inline'>
                  <Icon icon='home' />
                  <a href={property.floorPlanLink} target='_blank'>
                    Floor Plan
                  </a>
                </p>
              ) : null}
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              {property.virtualTourLink ? (
                <p className='mr-3 d-inline'>
                  <Icon icon='video' />
                  <a href={property.virtualTourLink} target='_blank'>
                    Virtual Tour
                  </a>
                </p>
              ) : null}
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              {property.feesSheetUrl ? (
                <p className='mr-3 d-inline'>
                  <Icon icon='dollar-sign' />
                  <a href={property.feesSheetUrl} target='_blank'>
                    Fee Sheet
                  </a>
                </p>
              ) : null}
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              {property.siteMapUrl ? (
                <p className='mr-3 d-inline'>
                  <Icon icon='map' />
                  <a href={property.siteMapUrl} target='_blank'>
                    Site Map
                  </a>
                </p>
              ) : null}
            </div>
          </Col>

          <Col lg='4'>
            <div className="d-none d-sm-block">
              {property.essentialHousing ? (
              <p className='mr-3 d-inline'> 
                <Icon icon='building' /> 
                <a href={property.essentialHousing} target='_blank'>
                Essential Housing
                </a>
              </p>
              ) : null }
            </div>
          </Col>
		
					{property.schedulingNote?.length > 5 && (
            <Col lg='12'>
              <div className="d-none d-sm-block mt-2">
                <p>
                  <strong>Scheduling Note:</strong>
                  <span className='ml-2'>{property.schedulingNote}</span>
                </p>
              </div>
            </Col>
          )}

          {property.commissionNote?.length > 5 && (
            <Col lg='12'>
              <div className="d-none d-sm-block mt-2">
                <p>
                  <strong>Commission Note:</strong>
                  <span className='ml-2'> {property.commissionNote}</span>
                </p>
              </div>
            </Col>
          )}

          {property.addonItems.length > 0 && (
            <Col lg='12'>
              <div className="d-none d-sm-block">
                <p>
                  <strong>Community Features / Amenities:</strong>
                  <span className='ml-2'> {property.addonItems.join(', ')}</span>
                </p>
              </div>
            </Col>
          )}

          {property.specialsUpdatedAt?.length > 5 && (
            <Col lg='12'>
              <div className="d-none d-sm-block mt-2">
                <p>
                  <strong>Commissions Last Updated:</strong>
                  <span className='ml-2'>{property.specialsUpdatedAt ? moment(property.specialsUpdatedAt).fromNow() : '-'}</span>
                </p>
              </div>
            </Col>
          )}

          {property.unitsUpdatedAt?.length > 5 && (
            <Col lg='12'>
              <div className="d-none d-sm-block mt-2">
                <p>
                  <strong>Pricing Last Updated:</strong>
                  <span className='ml-2'>{allTabsDisabled ? 'Pricing and availability for this community is not currently available.' : (property.unitsUpdatedAt ? moment(property.unitsUpdatedAt).fromNow() : '-')}</span>
                </p>
              </div>
            </Col>
          )}

          {property.policyItems.length > 0 && (
            <Col lg='12'>
              <div className="d-none d-sm-block">
                <p>
                  <strong>Community Policies:</strong>
                  <span className='ml-2'>{property.policyItems.join(', ')}</span>
                </p>
              </div>
            </Col>
          )}

          {property.virtualTours?.length > 5 && (
            <Col lg='12'>
              <div className="d-none d-sm-block">
                <p>
                  <strong>Virtual Tour:</strong>
                  <span className='ml-2'>{property.virtualTours}</span>
                </p>
              </div>
            </Col>
          )}

          {property.parkingNote?.length > 5 && (
            <Col lg='12'>
              <div className="d-none d-sm-block">
                <p>
                  <strong>Parking Note:</strong>
                  <span className='ml-2'>{property.parkingNote}</span>
                </p>
              </div>
            </Col>
          )}

          {property.invoicingNote?.length > 5 && (
            <Col lg='12'>
              <div className="d-none d-sm-block">
                <p>
                  <strong>Invoicing Note:</strong>
                  <span className='ml-2'>{property.invoicingNote}</span>
                </p>
              </div>
            </Col>
          )}

          {property.additionalNote && (
            <Col lg='12'>
              <div className="d-none d-sm-block">
                <p>
                  <strong>Additional Note:</strong>
                  <span className='ml-2'>{property.additionalNote}</span>
                </p>
              </div>
            </Col>
          )}

          {property.policyNote && (
            <Col lg='12'>
              <div className="d-none d-sm-block">
                <p>
                  <strong>Policy Note:</strong>
                  <span className='ml-2'>{property.policyNote}</span>
                </p>
              </div>
            </Col>
          )}


          {localStorage.getItem('is_broker') === 'true' && (
            <>
              <Col lg="12">
                <div className="mb-2">
                  <Button 
                    id="brokerNotesCollapse"
                    style={{ width: '250px' }}
                    type="button" 
                    outline
                    color='secondary'
                    onClick={toggleBrokerNotes}>
                    <Icon icon="comment" className="mr-2" />
                      Notes (For your agents)
                    <Icon icon="caret-down" className="ml-4" />
                  </Button>
                  <Collapse isOpen={brokerNotesCollapseOpen}>
                    <div className="theme-collapse custom-collapse">
                      <div className="custom-editor">
                        <SunEditor
                          setOptions={{
                            showPathLabel: false,
                            defaultStyle: 'width: 100%; min-height: 200px',
                            buttonList: [
                              ['undo', 'redo'],
                              ['bold', 'underline', 'italic'],
                              ['list', 'link', 'removeFormat'],
                            ],
                          }}
                          setContents={agentNotes}
                          onChange={setAgentNotes}
                        />
                      </div>
                      <Button
                        type="button"
                        onClick={() => handleUpdateUserPropertyNote('for_agents')}
                        className="sm mt-2"
                        color="primary"
                      >
                        Save
                      </Button>
                    </div>
                  </Collapse>
                </div>
              </Col>
            </>
          )}

          {localStorage.getItem('is_agent') === 'true' && (
            <>
              <Col lg="12">
                <div className="mb-2">
                  <Button
                    id="brokerNotesCollapse"
                    style={{ width: '250px' }}
                    type="button"
                    outline
                    color='secondary'
                    onClick={toggleBrokerNotes}
                  >
                    <Icon icon="comment" className="mr-2" />
                      Notes (From your broker)
                    <Icon icon="caret-down" className="ml-2" />
                  </Button>
                  <Collapse isOpen={brokerNotesCollapseOpen}>
                    <div className="theme-collapse custom-collapse">
                      <Card className="custom-card">
                        <CardBody>
                          {agentNotes ? (
                            <div dangerouslySetInnerHTML={{ __html: agentNotes }} />
                          ) : (
                            <p>No notes available.</p>
                          )}
                        </CardBody>
                      </Card>
                    </div>
                  </Collapse>
                </div>
              </Col>
            </>
          )}

          {((localStorage.getItem('user_type') === 'user') || (localStorage.getItem('is_broker') === 'true') || (localStorage.getItem('is_agent') === 'true')) && (
            <>
              <Col lg="12">
                <div className="mb-2">
                  <Button  
                    id="notesCollapse"
                    style={{ width: '250px' }}
                    type="button"
                    outline
                    color='secondary'
                    onClick={toggleAgentNotes}>
                    <Icon icon="comment" className="mr-2" />
                      <span className='mr-2'>Notes (Private to you)</span>
                    <Icon icon="caret-down" className="ml-4" />
                  </Button>
                  <Collapse isOpen={agentNotesCollapseOpen}>
                    <div className="theme-collapse custom-collapse">
                      <div className="custom-editor">
                        <SunEditor 
                          setOptions={{
                            showPathLabel: false,
                            defaultStyle: 'width: 100%; min-height: 200px',
                            buttonList: [
                              ['undo', 'redo'],
                              ['bold', 'underline', 'italic'],
                              ['list', 'link', 'removeFormat'],
                            ],
                          }}
                          setContents={selfNotes}
                          onChange={setSelfNotes}
                        />
                      </div>
                      <Button
                        type="button"
                        onClick={() => handleUpdateUserPropertyNote('for_self')}
                        className="sm mt-2 mb-4"
                        color="primary"
                      >
                        Save
                      </Button>
                    </div>
                  </Collapse>
                </div>
              </Col>
            </>
          )}
            
          <Col md='12' className='table-tabs'>
            <TabList
              unitTypes={unitTypes}
              property={property}
              selectedProperties={props.selectedProperties}
              setSelectedProperties={props.setSelectedProperties}
              pageName='showProperty'
            ></TabList>
          </Col>
          
          <ScrollToTopButton />

          {(property.lat && property.long) && (
            <Col md='12'>
              <Card className='theme-card mt-3'>
                <CardBody className='p-0' style={{ height: '450px' }}>
                  <SmallMap
                    property_ids={[property.id]}
                    height='450px'
                    showTooltip={false}
                    latlongs={[
                      {
                        lat: property.lat ? property.lat : '',
                        long: property.long ? property.long : '',
                        id: property.id,
                        property: property,
                      },
                    ]}
                    property={property}
                    is_show={true}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </>
  ) : (
    <div
      className='p-4 edit-popup'
      style={{ marginTop: '35%', marginLeft: '45%' }}
    >
      <h4>Loading ...</h4>
    </div>
  );
}
