import React, { useState, useEffect, useRef, useMemo } from 'react';
// import { createPortal } from 'react-dom';

import { unitTypes } from '@sparklib';
// import DatePicker from 'react-datepicker';
import { Icon } from '@assets';

import DataGrid from 'react-data-grid'; // TextEditor, // SelectColumn,

import CreatableSelect from 'react-select/creatable';
import { TextEditor, DateEditor } from '../gridEditors';

import {
  rowKeyGetter,
  getComparator,
  // inputStopPropagation,
  selectStopPropagation,
} from '../gridHelpers';
import { CurrencyFormatter, DateFormatter } from '../gridFormatters';
import FloorPlanPhotoEditor from './FloorPlanPhotoEditor';
import { PROPERTY } from '@api';
import { useLazyQuery } from '@apollo/client';

export default function FloorPlanGrid({
  // propertyName,
  floorPlans,
  setFloorPlans,
  addons,
  setIsChanged,
  units,
	setAddons,
  setUnits,
	reloadProperties,
	propertyDetails
}) {
  // const gridRef = useRef(null);
  const [sortColumns, setSortColumns] = useState([]);
  const bathTypes = ['Select', '1', '1.5', '2', '2.5', '3'];
	const [fpPhotoEditor, setFpPhotoEditor] = useState({ floorPlan: null, show: false });
  const unitsInFloorPlan = (floorPlanId) =>
    units.filter(
      (unit) => unit.type !== 'DELETED' && unit.floorPlanId === floorPlanId
    ).length;

	const [fetchProperty, {data: propertyData}] = useLazyQuery(PROPERTY, {
		onCompleted: (data) => {
			setupFloorPlans();
		},
		fetchPolicy: "network-only"
	})

	function refeshFloorPlans(){
		fetchProperty({
			variables: {
				id: Number(propertyDetails.id)
			}
		})
	}

	function setupFloorPlans(){
		if (propertyData){
			let tmpFloorPlans = propertyData.property?.floorPlans
			?.map((floorPlan) => {
        delete floorPlan.typeDetails;
        // delete floorPlan.isAvailable;
        // delete floorPlan['units'];
				return { type: 'UNCHANGED', ...floorPlan };
			})
			.concat([{ type: 'NEW', id: '-1100', unitFeatures: [] }]);
			// tmpFloorPlans = tmpFloorPlans.delete(isAvailable)
			// tmpFloorPlans = tmpFloorPlans.delete(units)s
			setFloorPlans(tmpFloorPlans.map(({ units, isAvailable, ...rest }) => rest));
			// setFloorPlans(current => {
			// 	const copy = {...current};

			// 	delete copy['units'];
	
			// 	return copy;
			// });
		}
	}

  const floorPlanColumns = [
    // SelectColumn,
    // // id
    // {
    //   key: 'id',
    //   name: 'ID',
    //   cellClass: 'text-center',
    //   width: 90,
    //   minWidth: 90,
    // },
    // name
    {
      key: 'name',
      name: 'Name',
      width: 160,
      minWidth: 160,
      resizable: true,
      sortable: true,
      editor: TextEditor,
    },
    // delete button
    {
      key: 'delete-button',
      name: '',
      minWidth: 30,
      width: 30,
      frozen: true,
      // isLastFrozenColumn: true,
      cellClass: 'text-center p-0',
      formatter({ row }) {
        if (row.type === 'NEW') {
          return (
            <>
              <Icon icon='plus' />
            </>
          );
        } else if (row.type === 'CREATE') {
          return (
            <>
              <div
                key={`del${row.id}`}
                className='w-100 text-center cursor-pointer'
                onClick={() => {
                  onFloorPlanRowDelete(row);
                  // onDeleteClick();
                }}
              >
                <Icon icon='times' />
              </div>
            </>
          );
        }
        return (
          <>
            <div
              key={`del${row.id}`}
              className='w-100 text-center cursor-pointer'
              onClick={() => {
                onFloorPlanRowDelete(row);
                // onDeleteClick();
              }}
            >
              <Icon icon='trash' />
            </div>
          </>
        );
      },
    },
    {
      key: 'copy-button',
      name: '',
      minWidth: 30,
      width: 30,
      frozen: true,
      cellClass: 'text-center p-0',
      formatter({ row }) {
        if (row.type === 'NEW') {
          return null;
        } else if (row.type === 'CREATE') {
          return (
            <>
              <div
                key={`copy${row.id}`}
                className='w-100 text-center cursor-pointer'
                onClick={() => onFloorPlanRowCopy(row)}
              >
                <Icon icon='clone' />
              </div>
            </>
          );
        }
        return (
          <>
            <div
              key={`copy${row.id}`}
              className='w-100 text-center cursor-pointer'
              onClick={() => onFloorPlanRowCopy(row)}
            >
              <Icon icon='clone' />
            </div>
          </>
        );
      },
    },
    // # of units
    {
      key: 'units',
      name: 'Units',
      width: 80,
      minWidth: 80,
      sortable: true,
      cellClass: 'text-center',
      formatter: ({ row }) => <>{unitsInFloorPlan(row.id)}</>,
    },
    // # of beds
    {
      key: 'bed',
      name: 'Bed',
      // headerRenderer: () => <div className='text-left'>Type</div>,
      width: 100,
      minWidth: 100,
      sortable: true,
      resizable: true,
      headerClass: 'text-left',
      formatter: ({ row }) => <>{unitTypes[row.bed]}</>,
      editor: ({ row, onRowChange }) => (
        <select
          autoFocus
          value={row.bed}
          onChange={(e) => {
            // console.log(e);
            onRowChange(
              {
                ...row,
                bed: e.target.value === 'Select' ? '' : e.target.value,
              },
              true
            );
          }}
          onKeyDown={selectStopPropagation}
        >
          {[
            <option value='' key={'select'}>
              {'Select'}
            </option>,
            ...Object.keys(unitTypes).map((unitType) => (
              <option value={unitType} key={unitType}>
                {unitTypes[unitType]}
              </option>
            )),
          ]}
        </select>
      ),
      editorOptions: {
        editOnClick: true,
      },
    },
    // bath
    {
      key: 'bath',
      name: 'Bath',
      cellClass: 'text-center',
      width: 60,
      cellClass: 'text-center',
      // resizable: true,
      sortable: true,
      // formatter: ({ row }) => row.bath || '-',
      editor: ({ row, onRowChange }) => (
        <select
          autoFocus
          value={row.bath}
          onChange={(e) => {
            // console.log(e);
            onRowChange(
              {
                ...row,
                bath: e.target.value === 'Select' ? '' : e.target.value,
              },
              true
            );
          }}
          onKeyDown={selectStopPropagation}
        >
          {bathTypes.map((bathType, index) => (
            <option value={bathType} key={bathType}>
              {bathType}
            </option>
          ))}
        </select>
      ),
      editorOptions: {
        editOnClick: true,
      },
    },
    // rent / price range
    {
      key: 'price',
      name: 'Price',
      // headerRenderer: () => <div className='text-right'>Price</div>,
      cellClass: 'text-center',
      width: 100,
      resizable: true,
      // sortable: true,
      // editor: TextEditor,
      formatter({ row }) {
        return row.price ? (
          <CurrencyFormatter value={Number(row.price)} />
        ) : (
          <></>
        );
      },
    },
    {
      key: 'rentMin',
      name: 'Price Min',
      // headerRenderer: () => <div className='text-right'>Price</div>,
			cellClass: 'text-right',
      width: 100,
      resizable: true,
      // sortable: true,
      // editor: TextEditor,
      formatter({ row }) {
        return row.rentMin ? (
          <CurrencyFormatter value={Number(row.rentMin)} />
        ) : (
          <></>
        );
      },
    },
    {
      key: 'rentMax',
      name: 'Price Max',
      // headerRenderer: () => <div className='text-right'>Price</div>,
			cellClass: 'text-right',
      width: 100,
      resizable: true,
      // sortable: true,
      // editor: TextEditor,
      formatter({ row, value }) {
        return row.rentMax ? (
          <CurrencyFormatter value={row.rentMax} />
        ) : (
          <></>
        );
      },
    },
    // sqft / size
    {
      key: 'sqft',
      name: 'Size',
      // headerRenderer: () => <div className='text-right'>Size</div>,
      cellClass: 'text-center',
      width: 80,
      resizable: true,
      sortable: true,
      editor: TextEditor,
    },
    {
      key: 'sqftMin',
      name: 'Size Min',
      // headerRenderer: () => <div className='text-right'>Size</div>,
      cellClass: 'text-center',
      width: 80,
      resizable: true,
      sortable: true,
      // editor: TextEditor,
    },
    {
      key: 'sqftMax',
      name: 'Size Max',
      // headerRenderer: () => <div className='text-right'>Size</div>,
      cellClass: 'text-center',
      width: 80,
      resizable: true,
      sortable: true,
      // editor: TextEditor,
    },
    {
      key: 'availableFrom',
      name: 'Available From',
      // headerRenderer: () => <div className='text-right'>Size</div>,
      cellClass: 'text-left',
      width: 120,
      resizable: true,
      // sortable: true,
      // editor: TextEditor,
			formatter({ row, onRowChange }) {
        return Date.parse(row.availableFrom) ? (
          <DateFormatter date={Date.parse(row.availableFrom)} />
        ) : (
          <></>
        );
      },
    },
    {
      key: 'availableTo',
      name: 'Available To',
      // headerRenderer: () => <div className='text-right'>Size</div>,
      cellClass: 'text-left',
      width: 120,
      resizable: true,
      // sortable: true,
      // editor: TextEditor,
			formatter({ row, onRowChange }) {
        return Date.parse(row.availableTo) ? (
          <DateFormatter date={Date.parse(row.availableTo)} />
        ) : (
          <></>
        );
      },
    },
    // description
    {
      key: 'description',
      name: 'Description',
      minWidth: 200,
      resizable: true,
      headerRenderer: () => <div className='text-left'>Description</div>,
      editor: TextEditor,
    },
    // virtual tour link
    {
      key: 'virtualTourLink',
      name: 'Virtual Tour Link',
      minWidth: 200,
      resizable: true,
      headerRenderer: () => <div className='text-left'>Virtual Tour Link</div>,
      editor: TextEditor,
    },
    // 2D Plan image
    {
      key: 'plan2d',
      name: '2D Image',
      minWidth: 100,
      resizable: false,
      headerRenderer: () => <div>2D Plan</div>,
      formatter: ({row}) => (
				<div
					className='cursor-pointer'
					align='center'
					onClick={() => setFpPhotoEditor({floorPlan: row, show: true}) }
				>
					{ row.plan2d ? <img src={row.plan2d} className='floor-plan-img' /> : <small>No Image</small> }
				</div>
			)
    },
    // 3D Plan image
    {
      key: 'plan3d',
      name: '3D Image',
      minWidth: 100,
      resizable: false,
      headerRenderer: () => <div>3D Plan</div>,
      editor: TextEditor,
    },
    // images
    {
      key: 'images',
      name: 'Images',
      minWidth: 100,
      resizable: false,
      headerRenderer: () => <div>Images</div>,
      editor: TextEditor,
    },
    // addon list
    {
      key: 'unitFeatures',
      name: 'Unit Features',
      width: 250,
      resizable: true,
      headerRenderer: () => <div className='text-left'>Unit Features</div>,
      formatter: ({ row, onRowChange }) =>
        row.unitFeatures && row.unitFeatures?.length < 2 ? (
          <>{row.unitFeatures[0]}</>
        ) : (
          <>
            {row.unitFeatures[0]} <small>+{row.unitFeatures?.length - 1}</small>
          </>
        ),
      editor: ({ row, onRowChange }) => (
        <CreatableSelect
          autoFocus
          name='Unit Features'
          isMulti
          value={row.unitFeatures?.map((feature) =>
            JSON.parse(JSON.stringify({ value: feature, label: feature }))
          )}
          // defaultMenuIsOpen
          options={
            typeof addons !== 'undefined'
              ? Object.values(addons).map((feature) =>
                  JSON.parse(
                    JSON.stringify({ value: feature.name, label: feature.name })
                  )
                )
              : []
          }
          onChange={(e) => {
            // console.log(e);
            onRowChange(
              {
                ...row,
                // addonList: e.map((feature) => feature.label),
                unitFeatures: e.map((feature) => feature.label),
              },
              true
            );
            // row.addonList = e.map((addon) => addon.label)
          }}
          // onChange={(e) => addFilter({ e, key: "selectedNeighborhood" })}
          className='grid-multi-select w-100'
          classNamePrefix='default-select'
          placeholder='Unit Features'
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          isSearchable
          menuPosition={'absolute'}
          menuPlacement={'auto'}
          menuShouldScrollIntoView={true}
        />
      ),
      editorOptions: {
        editOnClick: true,
      },
    },
  ];

  const sortedFloorPlans = useMemo(() => {
    let newFloorPlans = floorPlans.filter(
      (floorPlan) => floorPlan.type === 'NEW'
    );
    let tmpFloorPlans = floorPlans.filter(
      (floorPlan) => floorPlan.type !== 'DELETED' && floorPlan.type !== 'NEW'
    );

    const sortedFloorPlans = [...tmpFloorPlans];

    if (sortColumns.length === 0)
      return [...sortedFloorPlans, ...newFloorPlans];

    sortedFloorPlans.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    return [...sortedFloorPlans, ...newFloorPlans];
  }, [floorPlans, sortColumns]);

  function getRowType(rowtype) {
    switch (rowtype) {
      case 'NEW':
      case 'CREATE':
        return 'CREATE';
      case 'COPY':
        return 'COPY';
      case 'UNCHANGED':
      case 'CHANGED':
      default:
        return 'CHANGED';
    }
  }

  function onFloorPlanRowCopy(row) {
    const newFloorPlan = {
      name: `${row.name} (Copied)`,
      bed: row.bed,
      bath: row.bath,
      unitFeatures: row.unitFeatures,
      sqft: row.sqft,
      description: row.description,
      virtual_tour_link: row.virtual_tour_link,
      id: generateUniqueId(),
      type: 'CREATE',
    };

    setFloorPlans([...floorPlans, newFloorPlan]);
    setIsChanged(true);
  }
  
  function generateUniqueId() {
    return `copy-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  }
  
  function onFloorPlanRowDelete(row) {
    if (row.type === 'CREATE') {
      setFloorPlans(floorPlans.filter((floorPlan) => floorPlan.id !== row.id));
      setUnits(units.filter((unit) => unit.floorPlanId !== row.id));
    } else {
      const rowPosition = floorPlans.findIndex(
        (floorPlan) => floorPlan.id === row.id
      );
      const copiedFloorPlans = _.cloneDeep(floorPlans);
      copiedFloorPlans.splice(rowPosition, 1, {
        ...row,
        type: 'DELETED',
      });
      setFloorPlans(copiedFloorPlans);

      setUnits(
        units
          .filter(
            (unit) =>
              unit.floorPlanId !== row.id ||
              (unit.floorPlanId === row.id && unit.type !== 'CREATE')
          )
          .map((unit) => {
            return unit.floorPlanId === row.id
              ? { ...unit, type: 'DELETED' }
              : unit;
          })
      );

      setIsChanged(true);
    }
  }

  function onFloorPlansRowsChange(rows, { indexes, column }) {
    setIsChanged(true);
    const row = rows[indexes[0]];
    // console.log(row, '--- CHANGED THIS FLOOR PLAN', column, '---- at column');
    const copiedFloorPlans = _.cloneDeep(floorPlans);

    const rowPosition = floorPlans.findIndex(
      (floorPlan) => floorPlan.id === row.id
    );
    const addNewRow = row.type === 'NEW';
    // console.log(addNewRow, '---- addNewRow');
    const rowType = getRowType(row.type);

    copiedFloorPlans.splice(rowPosition, 1, {
      ...row,
      type: rowType,
    });
    addNewRow
      ? setFloorPlans([
          ...copiedFloorPlans,
          { type: 'NEW', id: (row.id - 1).toString(), unitFeatures: [] },
        ])
      : setFloorPlans(copiedFloorPlans);
  }

  useEffect(() => {
    console.log(floorPlans, '---- FLOORPLANS CHANGED');
  }, [floorPlans]);

  function onKeyDown(event) {
    if (event.isDefaultPrevented()) {
      event.stopPropagation();
    }
  }

  return (
    <>
			{
				fpPhotoEditor.show && 
				<FloorPlanPhotoEditor 
					fpPhotoEditor={fpPhotoEditor}
					setFpPhotoEditor={setFpPhotoEditor}
					reloadProperties={reloadProperties}
					propertyDetails={propertyDetails}
					refeshFloorPlans={refeshFloorPlans}
				/>
			}
      <div onKeyDown={onKeyDown} id='floor-plan-grid'>
        <DataGrid
          // ref={gridRef}
          rows={sortedFloorPlans}
          columns={floorPlanColumns}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          onRowsChange={onFloorPlansRowsChange}
          rowKeyGetter={rowKeyGetter}
          style={{ height: '64vh' }}
          rowHeight={() => 35}
          cellNavigationMode='NONE'
          enableCellAutoFocus={false}
          rowClass={(row) => (row.type === 'NEW' ? 'new-row' : undefined)}
        />
      </div>
    </>
  );
}
