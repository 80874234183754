import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../Spinner';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import { INVOICES, INVOICE_HISTORY_MULTI_DELETE, UPDATE_INVOICE_STATUS, SEND_INVOICE, MY_PROFILE, DOWNLOAD_INVOICE } from '@api';

import { useQuery, useMutation } from '@apollo/client';
import {
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import RTable from '../../RTable';
import InvoiceForm from './InvoiceForm';
import InvoiceTable from './InvoiceTable';
import SidebarRight from '../../SidebarRight';
import { Icon } from '@assets';
import InvoiceShow from './InoviceShow';
import Select from 'react-select';

const invoiceStatuses = [
  { value: 'drafted', label: 'Drafted' },
  { value: 'sent', label: 'Sent' },
  { value: 'followed_up', label: 'Followed Up' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'paid', label: 'Paid' }
];

export default function InvoiceHistory(props) {

  const [invoices, setInvoices] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
	const [isCheckedId, setIsCheckedId] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const [invoiceList, setInvoiceList] = useState('loading');
  const [allDeleteModal, setAllDeleteModal] = useState(false);
	const [updateProfile, setUpdateProfile] = useState(false);

	// const [resendModal, setResendModal] = useState(false);

	useQuery(MY_PROFILE, {
		onCompleted: (data) => {
			if (!data.myProfile.cityName && !data.myProfile.street1 && !data.myProfile.street2) {
				setUpdateProfile(true);
			} else {
				setUpdateProfile(false);
			}
		}
	})

	const { data: invoicesData, loading: invoicesLoading, refetch: invoicesRefetch } = useQuery(INVOICES, {
		onCompleted: (data) => {}
	});

	const [invoiceMultiDelete] = useMutation(INVOICE_HISTORY_MULTI_DELETE, {
    onCompleted: (data) => {
      setAllDeleteModal(!allDeleteModal);
      toast(data.invoicesMultiDelete.message, { type: 'success' });
      setIsProgress(false);
    },
    refetchQueries: [INVOICES],
    onError: (e) => {
      console.log(e);
    },
  });

	const [sendInvoiceMutation] = useMutation(SEND_INVOICE, {
		onCompleted: (data) => {
			if ( data.sendInvoice.errors === null || data.sendInvoice.errors.length === 0) {
				toast(data.sendInvoice.message, { type: 'success' });
			} else {
				toast(data.sendInvoice.message, { type: 'error' });
			}
		},
		refetchQueries: [INVOICES],
    onError: (e) => {
      console.log(e);
    }
	});

	const [downloadInvoice] = useMutation(DOWNLOAD_INVOICE, {
		onCompleted: async (data) => {
			if ( data.downloadInvoice.errors === null || data.downloadInvoice.errors.length === 0) {
        try {
          if (data.downloadInvoice.invoiceDownloadLink) {
            const invoicePdfLink = data.downloadInvoice.invoiceDownloadLink;
            // Open the PDF in a new tab
            window.open(invoicePdfLink, '_blank');
            const fileName = invoicePdfLink.substring(invoicePdfLink.lastIndexOf('/') + 1) || 'Invoice.pdf';
            // Fetch the PDF file
            // const fileResponse = await fetch(invoicePdfLink);
						const fileResponse = await fetch(invoicePdfLink, {
							method: 'GET',
							credentials: 'include',
						});
            const blob = await fileResponse.blob();
            // Create a blob URL
            const blobUrl = window.URL.createObjectURL(blob);
            // Create a temporary anchor element to trigger the download
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            // Clean up the DOM
            link.remove();
            window.URL.revokeObjectURL(blobUrl);
          }
        } catch (err) {
          console.error('Error downloading invoice:', err);
        }
				toast(() => (
					<a href={data.downloadInvoice.invoiceDownloadLink} target='_blank' download>
						Click here to download PDF
					</a>
				), { type: 'success' });
			} else {
				toast(data.downloadInvoice.message, { type: 'error' });
			}
		},
    onError: (e) => {
      console.log(e);
    }
	});

	const [updateInvoiceStatus] = useMutation(UPDATE_INVOICE_STATUS, {
		onCompleted: (data) => {
			if (data.updateInvoiceStatus.errors.length > 0) {
				toast(data.updateInvoiceStatus.message, { type: 'error' });
			} else {
				toast(data.updateInvoiceStatus.message, { type: 'success' });
			}
		},
		refetchQueries: [INVOICES],
    onError: (e) => {
      console.log(e);
    }
	});

	const handleDeleteAll = async () => {
    setIsProgress(true);
    await invoiceMultiDelete({
      variables: {
        ids: isCheckedId,
      },
    });
  };

	useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      isSelectedIds.push(parseInt(key.original.node.id));
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);

  useEffect(() => {
    if (refetch === true) {
      invoicesRefetch();
      setRefetch(false);
    }
  }, [refetch]);


  const addInvoiceHistory = () => {
    setSidebarState(true);
  };

  // const onResendPopup = () => {
  //   if (resendMailId) {
  //     setResendModal(!resendModal);
  //   }
  // };

	function sendInvoice(id) {
		sendInvoiceMutation({
			variables: {id: Number(id)}
		})
	}

	function invoiceDownload(id) {
		downloadInvoice({
			variables: {id: Number(id)}
		})
	}

	function invoiceStatusUpdate(id, status){
		updateInvoiceStatus({
			variables: {id: Number(id), newStatus: status}
		})
	}

	useEffect(() => {
		if (invoicesData) {
			if (invoicesData.invoices.length === 0) {
				setInvoices('noData')
			} else {
				setInvoices(invoicesData.invoices.edges);
			}
		}
	}, [invoicesData])

	const handleSingleDelete = (id) => {
    setIsCheckedId([parseInt(id)]);
    setAllDeleteModal(true);
  };

  const onAllDeletePopup = () => {
    if (isCheckedId.length > 0) {
      setAllDeleteModal(!allDeleteModal);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Agent',
        accessor: 'node.user.name',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Invoice ID',
        accessor: 'node.invoiceNumber',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Property',
        accessor: 'node.property.name',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Client',
        accessor: 'node.client.name',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Lease Amount',
        accessor: 'node.leaseAmount',
        Cell: ({ value, row }) => (
          <div>
            {value ? '$' + value : '-'}
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'node.status',
				width: 200,
        Cell: ({ value, row}) => (
					<>
						<div onClick={(e) => e.stopPropagation()}>
							<Select
								name='status'
								isMulti={false}
								value={invoiceStatuses.find(s => s.value === value)}
								options={invoiceStatuses}
								onChange={(e) => invoiceStatusUpdate(row.original.node.id, e.value)}
								className='multi-select-client w-100'
								classNamePrefix='default-select'
								placeholder='Select Status'
							/>
						</div>
					</>
        ),
      },
      {
        Header: 'Submitted On',
        accessor: 'node.submittedOn',
        Cell: ({ value, row }) => <div>{value ? moment(value).format('LLL') : '-'}</div>,
      },
      {
        Header: 'Paid On',
        accessor: 'node.paidOn',
        Cell: ({ value, row }) => (
          <div>{value ? moment(value).format('LLL') : '-'}</div>
        ),
      },
			{
        Header: 'Action',
        accessor: 'node.id',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div onClick={(e) => e.stopPropagation()}>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret>
                <Icon icon='bars' />
              </DropdownToggle>
							<DropdownMenu>
							<DropdownItem onClick={() => {
										setSidebarState(true);
										history.push(`/invoices/show/${value}`)
									}}
								>
									View
								</DropdownItem> 
								<DropdownItem divider />
									{row.original.node.status !== 'paid' &&
										<>
											<DropdownItem onClick={() => {
													setSidebarState(true);
													history.push(`/invoices/edit/${value}`);
												}}
											>
												Edit
											</DropdownItem>
											<DropdownItem divider />
										</>
									}	
								<DropdownItem onClick={() => sendInvoice(value)}>
									{row.original.node.status !== 'drafted' ? "Resend Invoice" : "Send Invoice"}
								</DropdownItem> 
								<DropdownItem divider />
								<DropdownItem onClick={() => handleSingleDelete(value)}>
									Delete
								</DropdownItem>
								<DropdownItem divider />
								<DropdownItem onClick={() => invoiceDownload(value)}>
									Download Invoice
								</DropdownItem>
								<DropdownItem divider />
								{ row.original.node.status === 'drafted' ?
									<>
										<DropdownItem onClick={() => invoiceStatusUpdate(value, "sent")}>
											Set As Sent
										</DropdownItem>
										<DropdownItem divider />
										<DropdownItem onClick={() => invoiceStatusUpdate(value, "paid")}>
											Set As Paid
										</DropdownItem>
									</> : 
									row.original.node.status === 'sent' ?
									<>
										<DropdownItem onClick={() => invoiceStatusUpdate(value, "paid")}>
											Set As Paid
										</DropdownItem>
									</> : 
									<>
										<DropdownItem onClick={() => invoiceStatusUpdate(value, "sent")}>
											Set As Unpaid
										</DropdownItem>
									</> 
								}
							</DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
    ],
    []
  );
	const history = useHistory();
  let { id, operation } = useParams();
  const [sidebarState, setSidebarState] = useState(operation ? true : false);

  useEffect(() => {
    // check !id and operation is edit
    sidebarState
      ? !id && history.push(`/invoices/new`)
      : history.push(`/invoices`);
  }, [sidebarState, operation]);

	function handleUpdateProfile () {
		history.push(`/settings`)
	}


  return (
    <>
		  { invoicesLoading && <Spinner /> }

			{operation === "edit" ?
				<SidebarRight
					sidebarState={sidebarState}
					sidebarToggle={() => {
						setSidebarState(!sidebarState);
					}}
					sidebarWidth='950px'
					sidebarContent={
						<InvoiceForm
							new={operation}
							sidebarToggle={() => {
								setSidebarState(!sidebarState);
							}}
							shouldRefetch={() => setRefetch(true)}
							{...props}
						/>
					}
				/> : operation === "show" ? 
				<SidebarRight
					sidebarState={sidebarState}
					sidebarToggle={() => {
						setSidebarState(!sidebarState);
					}}
					sidebarWidth='950px'
					sidebarContent={
						<InvoiceShow
							new={operation}
							setSidebarState={setSidebarState}
							sidebarToggle={() => {
								setSidebarState(!sidebarState);
							}}
							shouldRefetch={() => setRefetch(true)}
							{...props}
						/>
					}	
				/>
				 : 
				<SidebarRight
					sidebarState={sidebarState}
					sidebarToggle={() => {
						setSidebarState(!sidebarState);
					}}
					sidebarWidth='950px'
					sidebarContent={
						<InvoiceForm
							new={operation}
							sidebarToggle={() => {
								setSidebarState(!sidebarState);
							}}
							shouldRefetch={() => setRefetch(true)}
							{...props}
						/>
					}
				/>
			}		

			<Modal isOpen={updateProfile}>
        <ModalHeader>Update Profile</ModalHeader>
        <ModalBody>
          Update your address, city, state and zip to create an invoice
        </ModalBody>
        <ModalFooter>
          <Button
            color='success'
            // disabled={isProgress}
            onClick={handleUpdateProfile}
          >
            Sure
          </Button>
        </ModalFooter>
      </Modal>	

			<Modal isOpen={allDeleteModal} toggle={onAllDeletePopup}>
        <ModalHeader toggle={onAllDeletePopup}>Delete Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to delete {isCheckedId.length} invoice
          {isCheckedId.length > 1 ? 's' : ''}?
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            disabled={isProgress}
            onClick={handleDeleteAll}
          >
            Yes
          </Button>
          <Button color='success' onClick={onAllDeletePopup}>
            No
          </Button>
        </ModalFooter>
      </Modal>
     
      <Row className='mt-md-4'>
        <Col md='12'>
          {invoices.length > 0 ? (
            <InvoiceTable
              columns={columns}
              data={invoices}
              filterable

              addons={
                <Button
                  color='red'
                  onClick={onAllDeletePopup}
                  disabled={isCheckedId.length > 0 ? false : true}
                  className='button-dashboard float-right mr-sm-5 mt-3 mt-sm-0'
                >
                <Icon icon='trash-alt' className='mr-2' />
                  Delete
                </Button>
              }
              changeIsChecked={(isChecked) => setIsChecked(isChecked)}
							setSidebarState={setSidebarState}
							
            />
          ) : (
            <>
              {invoices.length === 0 ? (
                <div className='text-center' style={{ margin: '35vh 0' }}>
                  <h4>You haven't sent any invoice so far.</h4>
                  <Button
                    onClick={addInvoiceHistory}
                    color='success'
                    className='button-dashboard mt-2'
                  >
                    Create and send an invoice now{' '}
                    <Icon icon='chevron-right' className='ml-2' />
                  </Button>
                </div>
              ) : null}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
