import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  List,
} from 'reactstrap';
import { CLIENT_REPORTS, DELETE_REPORT_PROPERTY } from '@api';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { unitTypes } from '@sparklib';
import ClientPropertyReport from './ClientPropertyReport';

export default function ClientShow({ searchMatchingProperties, client }) {
  // const numberFormatter = new Intl.NumberFormat('en-US');
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [clientReports, setClientReports] = useState([]);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [deleteRecommendation, setDeleteRecommendation] = useState({});

  const toggle = () => setTooltipOpen(!tooltipOpen);

  // const {loading: loadingClient} = useQuery(CLIENT, {
  // 	skip: clientId == null,
  // 	variables: { id: Number(clientId) },
  // 	onCompleted: (data) => {
  // 		refetchClientReports()
  // 	}
  // })

  const [refetchClientReports, { data: reportsData, loading: reportsLoading }] =
    useLazyQuery(CLIENT_REPORTS, {
      fetchPolicy: 'network-only',
      variables: { clientId: Number(client?.id) },
      onCompleted: (data) => {
        setClientReports(data?.clientReports || []);
      },
      skip: !client?.id,
    });

  const [deleteReportProperty] = useMutation(DELETE_REPORT_PROPERTY, {
    onCompleted: (data) => {
      setShowModal(false);
      setDeleteRecommendation({});
      refetchClientReports();
    },
    onError: (e) => {
      console.log(e);
    },
  });

  function arrayToString(array) {
    let str = '';
    array.forEach((e) => (str += ` ${e},`));
    return str.slice(0, -1);
  }

  function getBedType(array) {
    let str = '';
    array?.forEach((e) => (str += ` ${unitTypes[e]},`));
    return str.slice(0, -1);
  }

  function tootTip(txt) {
    return (
      <UncontrolledTooltip
        placement='right'
        target='UncontrolledTooltipExample'
      >
        {txt}
      </UncontrolledTooltip>
    );
  }

  function handlePropertyDelete(reportProperyId, reportId) {
    setShowModal(true);
    setDeleteRecommendation({
      ids: Array.isArray(reportProperyId)
        ? reportProperyId
        : [Number(reportProperyId)],
      clientId: Number(client.id),
      reportId: Number(reportId),
    });
  }

  function handleDeleteRecommendation() {
    deleteReportProperty({ variables: deleteRecommendation });
  }

  useEffect(() => {
    if (client?.id) {
      refetchClientReports();
    }
    return () => {
      setClientReports([]);
    };
  }, [client?.id, refetchClientReports]);

  return (
    <>
      {showModal && (
        <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(!showModal)}>
            Delete Confirmation
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete {deleteRecommendation.ids.length}{' '}
            recommendation{deleteRecommendation.ids.length > 1 ? 's' : ''}
          </ModalBody>
          <ModalFooter>
            <Button
              color='danger'
              disabled={!showModal}
              onClick={handleDeleteRecommendation}
            >
              Yes
            </Button>
            <Button color='success' onClick={() => setShowModal(false)}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      )}

      <hr />
      <div className='px-1'>
        <Row>
          <Col className='mt-md-4'>
            <Row>
              <Col md='12' className='my-3'>
                <h4 className='d-inline-block mb-md-0 mr-4'>Sent Properties</h4>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                {clientReports?.length > 0 ? (
                  clientReports.map((report) => {
                    return (
                      <ClientPropertyReport
                        report={report}
                        reportProperties={report.reportProperties}
                        key={report.id}
                        tootTip={tootTip}
                        handlePropertyDelete={handlePropertyDelete}
                        clientId={client.id}
                        isLoading={reportsLoading}
                        refetchClientReports={refetchClientReports}
                        searchMatchingProperties={searchMatchingProperties}
                        client={client}
                      />
                    );
                  })
                ) : (
                  <div className='mt-3'>
                    <p>You haven’t sent any recommendations to this client.</p>
                    <Button
                      color='success'
                      className='btn btn-rounded mt-2'
                      onClick={() => searchMatchingProperties(client)}
                    >
                      Find and Send Recommendations Now
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
